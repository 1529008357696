<template>
    <div class="giro-card">
        <div class="header">{{ $t('general.bank-data') }}</div>
        <div class="middle">
            <img class="chip" src="@/assets/chip.png" />
            <img class="giro-logo" src="@/assets/girocard-logo.svg" />
        </div>
        <div id="a3RvbmFtZQ==" class="owner">{{ name }}</div>
        <div id="a3RvYWRkcmVzcw==" class="address">{{ address }}</div>
        <div v-if="additionalinfo" class="additionalinfo">{{ additionalinfo }}</div>
        <div class="account">
            <div id="a3RvaWJhbg==" class="iban">
                <div>{{ $t('general.iban') }}</div>
                <div>{{ iban }}</div>
            </div>
            <div id="a3RvYmlj" v-if="bic" class="bic">
                <div>{{ $t('general.bic') }}</div>
                <div>{{ bic }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GiroCard',
    props: {
        name: {
            type: String,
            default: 'Maxima Musterfrau'
        },
        address: {
            type: String,
            default: 'Schwimmbadstraße 26, 79100 Freiburg Wiehre, DE'
        },
        additionalinfo: {
            type: String
        },
        iban: {
            type: String,
            default: 'DE84500105171651268192'
        },
        bic: {
            type: String,
            default: 'INGDDEFFXXX'
        }
    }
}
</script>

<style scoped>
.giro-card {
    position: relative;

    background-color: #fff;
    
    /* background: rgb(2,0,36);
    background: linear-gradient(344deg, rgba(2,0,36,1) 0%, rgba(16,16,124,1) 35%, rgba(0,212,255,1) 100%);
    color: #fff; */

    /* background: rgb(179,179,179);
    background: linear-gradient(344deg, rgba(179,179,179,1) 0%, rgba(224,233,255,1) 35%, rgba(255,255,255,1) 100%); */

    background: rgb(255,255,255);
    background: radial-gradient(circle, rgba(255,255,255,1) 45%, rgba(209,209,209,1) 100%);

    width: 350px;
    min-height: 200px;
    padding: 10px 20px 10px 20px;
}

.header {
    font-size: 1.3em;
}

.middle {
    width: 100%;
}

.middle > .chip {
    width: 100px;
    padding-left: 20px;
}

.middle > .giro-logo {
    height: 60px;
    margin-left: 120px;
    margin-bottom: 20px;
    border: 1px solid #fff;
}

.owner {
    margin-top: 10px;
    font-size: 1.4em;
    font-weight: bold;
}

.address,
.additionalinfo {
    margin-top: 5px;
    margin-left: 5px;
}

.account {
    display: flex;
    margin-top: 20px;
}

.account > .iban > div:nth-of-type(1),
.account > .bic > div:nth-of-type(1) {
    margin-left: 5px;
    font-size: 0.95em;
}

.account > .iban > div:nth-of-type(2),
.account > .bic > div:nth-of-type(2) {
    font-weight: bold;
}

.account > .bic {
    margin-left: 15px;
}
</style>